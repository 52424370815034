<template>
<div>
  <b-card
  no-body
  class="mb-50"
  >
  <div class="m-1">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
        <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search by Bet Customer Phone or Customer Name..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="bets-filter-select"
              :reduce="val => val.value"
             @input="(val) => $emit('update:statusFilter', val)"
              placeholder="Filter by Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>

      </b-row>

    </div>
  </b-card>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <b-table
      ref="refBetListTable"
      :items="fetchBets"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy.sync="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
          label="Loading..."
          class="align-middle">
          </b-spinner>
          <strong class=" ml-2">Fetching...</strong>
        </div>
      </template>
      <!-- Column: Id -->
      <template #cell(betId)="data">
        <span class="text-nowrap">
          # {{ data.item.betId }}
        </span>
      </template>
      <template #cell(player_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
             :id="`bet-row-${data.item.id}`"
              size="32"
              :text="avatarText(data.item.player_name)"
              :variant="`light-${resolveBetAvatarVariant(data.item.status)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.player_name }}
          </span>
          <small class="text-muted">{{ data.item.player_msisdn }}</small>
        </b-media>
      </template>
      <template #cell(status)="data">
        <span class="text-nowrap">
          <b-badge
            pill
            :variant="`light-${resolveBetStatusVariantAndIcon(data.item.status).variant}`"
          >
            {{ data.item.status }}
          </b-badge>
        </span>
      </template>
      <template #cell(amount)="data">
        <span class="text-nowrap">
          {{ Number(data.item.amount).toLocaleString() }}
        </span>
      </template>
      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap">
          {{ data.item.created_at }}
        </span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBets"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BButton,
  BPagination, BSpinner, BBadge,
  BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useBetsList from './useBetsList'

import betsStoreModule from '../betsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BSpinner,
    BBadge,
    BButton,
    vSelect,
  },
  props: {
    playerData: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const BETS_APP_STORE_MODULE_NAME = 'bets'

    // Register module
    if (!store.hasModule(BETS_APP_STORE_MODULE_NAME)) store.registerModule(BETS_APP_STORE_MODULE_NAME, betsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BETS_APP_STORE_MODULE_NAME)) store.unregisterModule(BETS_APP_STORE_MODULE_NAME)
    })
    const statusOptions = [
      { label: 'Placed', value: 'Placed' },
      { label: 'Lost', value: 'Lost' },
      { label: 'Won', value: 'Won' },
      { label: 'Cancelled', value: 'Cancelled' },
    ]

    const {
      fetchBets,
      tableColumns,
      perPage,
      currentPage,
      totalBets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBetListTable,

      statusFilter,
      isBusy,

      refetchData,

      resolveBetStatusVariantAndIcon,
      resolveBetAvatarVariant,
    } = useBetsList(props)

    return {
      fetchBets,
      tableColumns,
      perPage,
      currentPage,
      totalBets,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBetListTable,
      isBusy,

      statusFilter,
      statusOptions,

      refetchData,
      avatarText,
      resolveBetStatusVariantAndIcon,
      resolveBetAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.bets-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
